export enum COMPANY_CATEGORY {
    NORMAL_COMPANY = 'NORMAL_COMPANY',
    PERSONAL_SUPPLIER = 'PERSONAL_SUPPLIER',
}

export enum COMMON_COUNTRY {
    MAINLAND = '156',
    HK = '344',
    MACAU = '446',
    TAIWAN = '158'
}

export enum INVITE_TYPE {
    MOBILE = 'MOBILE',
    EMAIL = 'EMAIL',
}

export enum CONTRACT_TYPE {
    MOBILE = 'MOBILE',
    EMAIL = 'EMAIL',
}
