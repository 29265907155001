
import { Component, Vue } from 'vue-property-decorator'
import { setLocaleCookie, getLocaleFromCookie } from '@/locales'
import API from '@/api/api'
import { COMMON_COUNTRY } from '@/enum'
import TCaptcha007 from '@futuweb/business-act/es5/TCaptcha007'
import JSEncrypt from 'jsencrypt'

@Component({
    name: 'Login',
})
export default class Login extends Vue {
    /* data */
    languageOpt = [
        {
            code: 'zh-CN',
            desc: '简体中文',
        },
        // {
        //     code: 'en-US',
        //     desc: 'English',
        // },
    ]

    usedLanguage = {
        code: 'zh-CN',
        desc: '简体中文',
    }

    loginForm = {
        username: '',
        password: '',
        securityCode: '',
    }

    showErrorTips = false

    errorMsg = '请输入登录名/手机号！'

    isSending = false

    secondsRemaining = 60

    countryCode = ''

    countryList: any[] = []

    rsaKey = ''

    encryptor = new JSEncrypt()
    /* life cycle */
    async created () {
        document.title = '登录'
        await Promise.all([
            this.syncLanguage(),
            this.getCountry(),
            this.getRsaKey(),
        ])
        this.countryCode = COMMON_COUNTRY.MAINLAND
    }

    /* method */
    async getRsaKey () {
        const result = await API.getRsaKey()
        this.rsaKey = result.data
        this.encryptor.setPublicKey(this.rsaKey)
    }

    async login () {
        const isValid = this.validateForm()
        if (isValid) {
            const result = await API.login({
                ...this.loginForm,
                password: this.encryptor.encrypt(this.loginForm.password) as string,
            })
            if (result.code !== 0) {
                // this.errorMsg = result.errorMessage
                // this.showErrorTips = true
                this.loginForm.securityCode = ''
            } else {
                location.assign('/supplier')
            }
        }
    }

    async changeLang (val: string) {
        if (this.usedLanguage.code === val) {
            return
        }
        setLocaleCookie(val)
        location.reload()
    }

    syncLanguage () {
        const lang = getLocaleFromCookie() || 'zh-CN'
        setLocaleCookie(lang)
        this.usedLanguage = this.languageOpt.filter((item) => {
            return item.code === lang
        })[0]
    }

    getCaptcha () {
        let isValid = true
        if (!this.loginForm.username) {
            this.showErrorTips = true
            this.errorMsg = this.$t('loginNameRequired') as string
            isValid = false
        } else {
            this.showErrorTips = false
            isValid = true
            this.errorMsg = ''
        }
        if (isValid) {
            const captcha = new TCaptcha007(
                {
                    // appid
                    appid: 2094350478,
                    option: {
                        userLanguage: 'zh-cn',
                        bizState: null,
                    },
                    // 是否使用国际站防水墙
                    useForeignCaptcha: false,
                    success: (res) => {
                        // res.ticket 验证票据
                        // res.appid appid
                        // res.randstr 随机字符串（用于传回给后端）
                        // 调用验票接口
                        this.verifyTicket(res)
                    },
                    fail: (err) => {
                        // errCode 错误码，如1006
                        // errorMessage 错误信息，如 "get_captcha_config_request_error"
                        if (err) {}
                    },
                    close: (res) => {
                        // console.log('res', res)
                    },
                },
            )
            captcha.show()
        }
    }

    async getAuthCode (ticket) {
        await API.getAuthCode(
            {
                contract: this.loginForm.username,
                contractType: this.loginForm.username.indexOf('@') > -1 ? 'EMAIL' : 'MOBILE',
                source: 'login',
                numCode: this.countryCode,
                ticket,
            },
        )
        this.isSending = true
        this.countdown()
    }

    async verifyTicket (data) {
        // const result = await API.verifyTicket(data)
        await this.getAuthCode(data)
    }

    async getCountry () {
        const result = await API.getCountry()
        this.countryList = result.data
    }

    countdown () {
        // Decrement the seconds remaining and update the button text
        this.secondsRemaining--
        if (this.secondsRemaining > 0) {
            setTimeout(() => {
                this.countdown()
            }, 1000)
        } else {
        // Reset the button text and seconds remaining
            this.isSending = false
            this.secondsRemaining = 60
        }
    }

    toRegister () {
        location.assign('/supplier/register')
    }

    toReset () {
        location.assign('/supplier/reset')
    }

    validateForm () {
        if (!this.loginForm.username) {
            this.showErrorTips = true
            this.errorMsg = this.$t('loginNameRequired') as string
            return false
        } else if (!this.loginForm.password) {
            this.showErrorTips = true
            this.errorMsg = this.$t('loginPwdRequired') as string
            return false
        } else if (!this.loginForm.securityCode) {
            this.showErrorTips = true
            this.errorMsg = this.$t('loginCodeRequired') as string
            return false
        } else {
            this.showErrorTips = false
            return true
        }
    }
}

