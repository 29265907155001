
import { Component, Vue } from 'vue-property-decorator'
import { CONTRACT_TYPE, COMMON_COUNTRY } from '@/enum'
import { setLocaleCookie, getLocaleFromCookie } from '@/locales'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import regExpUtils from '@/utils/regExp'
import API from '@/api/api'
import TCaptcha007 from '@futuweb/business-act/es5/TCaptcha007'
import JSEncrypt from 'jsencrypt'

@Component({
    name: 'Reset',
    components: {
        VuePhoneNumberInput,
    },
})
export default class Reset extends Vue {
    /* data */
    step = 1

    contractType = CONTRACT_TYPE.MOBILE

    contractTypeEnum = CONTRACT_TYPE

    languageOpt = [
        {
            code: 'zh-CN',
            desc: '简体中文',
        },
        // {
        //     code: 'en-US',
        //     desc: 'English',
        // },
    ]

    usedLanguage = {
        code: 'zh-CN',
        desc: '简体中文',
    }

    mobileForm = {
        countryCode: '',
        contract: '',
        securityCode: '', // 验证码
        contractType: 'MOBILE', // 类型 手机MOBILE  邮箱EMAIL
    }

    mobileRules = {
        countryCode: [
            { required: true, message: this.$t('countryRequire'), trigger: ['blur'] },
        ],
        contract: [
            { required: true, message: this.$t('mobileRequired'), trigger: ['blur'] },
        ],
        securityCode: [
            { required: true, message: this.$t('securityCodeRequired'), trigger: ['blur'] },
        ],
    }

    emailForm = {
        countryCode: '',
        contract: '',
        securityCode: '', // 验证码
        contractType: 'MOBILE', // 类型 手机MOBILE  邮箱EMAIL
    }

    emailRules = {
        countryCode: [
            { required: true, message: this.$t('countryRequire'), trigger: ['blur'] },
        ],
        contract: [
            { required: true, message: this.$t('emailRequired'), trigger: ['blur'] },
            { required: true, trigger: 'blur', validator: this.validateEmail },
        ],
        securityCode: [
            { required: true, message: this.$t('securityCodeRequired'), trigger: ['blur'] },
        ],
    }

    passwordForm = {
        passWord: '', // 新密码（ras加密）
        encryptPassword: '', // 重复新密码（ras加密）
    }

    passwordRules = {
        passWord: [
            { required: true, trigger: ['blur'], validator: this.validatePassword },
        ],
        encryptPassword: [
            { required: true, trigger: 'blur', validator: this.validatePasswordConfirm },
        ],
    }

    isSending = false

    secondsRemaining = 60

    countryList: any[] = []

    rsaKey = ''

    encryptor = new JSEncrypt()
    /* life cycle */
    async created () {
        document.title = '重置密码'
        await Promise.all([
            this.syncLanguage(),
            this.getCountry(),
            this.getRsaKey(),
        ])
        this.mobileForm.countryCode = COMMON_COUNTRY.MAINLAND
        this.emailForm.countryCode = COMMON_COUNTRY.MAINLAND
    }

    /* method */
    syncLanguage () {
        const lang = getLocaleFromCookie() || 'zh-CN'
        this.usedLanguage = this.languageOpt.filter((item) => {
            return item.code === lang
        })[0]
    }

    async getRsaKey () {
        const result = await API.getRsaKey()
        this.rsaKey = result.data
        this.encryptor.setPublicKey(this.rsaKey)
    }

    async getCountry () {
        const result = await API.getCountry()
        this.countryList = result.data
    }

    switchTab (tab: CONTRACT_TYPE) {
        if (this.step === 1) {
            if (tab === CONTRACT_TYPE.MOBILE) {
                (this.$refs.emailForm as any).resetFields()
            } else {
                (this.$refs.mobileForm as any).resetFields()
            }
            this.contractType = tab as CONTRACT_TYPE
        }
    }

    async changeLang (val: string) {
        if (this.usedLanguage.code === val) {
            return
        }
        setLocaleCookie(val)
        location.reload()
    }

    getCaptcha () {
        if (this.contractType === CONTRACT_TYPE.MOBILE) {
            if (this.mobileForm.contract) {
                this.showCaptha()
            } else {
                this.$message.warning(this.$t('mobileRequired') as string)
            }
        } else {
            if (this.emailForm.contract) {
                this.showCaptha()
            } else {
                this.$message.warning(this.$t('emailRequired') as string)
            }
        }
    }

    showCaptha () {
        const captcha = new TCaptcha007(
            {
                // appid
                appid: 2094350478,
                option: {
                    userLanguage: 'zh-cn',
                    bizState: null,
                },
                // 是否使用国际站防水墙
                useForeignCaptcha: false,
                success: (res) => {
                    // res.ticket 验证票据
                    // res.appid appid
                    // res.randstr 随机字符串（用于传回给后端）
                    // 调用验票接口
                    this.verifyTicket(res)
                },
                fail: (err) => {
                    // errCode 错误码，如1006
                    // errorMessage 错误信息，如 "get_captcha_config_request_error"
                    if (err) {}
                },
                close: (res) => {
                    // console.log('res', res)
                },
            },
        )
        captcha.show()
    }

    async verifyTicket (data) {
        // const result = await API.verifyTicket(data)
        await this.getAuthCode(data)
    }

    async getAuthCode (ticket) {
        await API.getAuthCode(
            {
                contract: (
                    this.contractType === CONTRACT_TYPE.MOBILE
                        ? this.mobileForm.contract.replaceAll(' ', '')
                        : this.emailForm.contract
                ),
                contractType: this.contractType === CONTRACT_TYPE.MOBILE ? 'MOBILE' : 'EMAIL',
                source: 'reset',
                numCode: this.contractType === CONTRACT_TYPE.MOBILE ? this.mobileForm.countryCode : this.emailForm.countryCode,
                ticket,
            },
        )
        this.isSending = true
        this.countdown()
    }

    countdown () {
        // Decrement the seconds remaining and update the button text
        this.secondsRemaining--
        if (this.secondsRemaining > 0) {
            setTimeout(() => {
                this.countdown()
            }, 1000)
        } else {
        // Reset the button text and seconds remaining
            this.isSending = false
            this.secondsRemaining = 60
        }
    }

    next (step: number) {
        if (step === 1) {
            this.step = 1
        } else if (step === 2) {
            if (this.contractType === this.contractTypeEnum.MOBILE) {
                (this.$refs.mobileForm as any).validate(async (valid) => {
                    if (valid) {
                        const result = await API.checkReset({
                            // ...this.mobileForm,
                            contract: this.mobileForm.contract.replaceAll(' ', ''),
                            contractType: 'MOBILE',
                            securityCode: this.mobileForm.securityCode,
                        })
                        this.step = step
                    }
                })
            } else {
                (this.$refs.emailForm as any).validate(async (valid) => {
                    if (valid) {
                        const result = await API.checkReset({
                            // ...this.emailForm,
                            contract: this.emailForm.contract,
                            securityCode: this.emailForm.securityCode,
                            contractType: 'EMAIL',
                        })
                        this.step = step
                    }
                })
            }
        } else if (step === 3) {
            (this.$refs.passwordForm as any).validate(async (valid) => {
                if (valid) {
                    let contract
                    if (this.contractType === CONTRACT_TYPE.EMAIL) {
                        contract = this.emailForm.contract
                    } else {
                        contract = this.mobileForm.contract.replaceAll(' ', '')
                    }
                    const result = await API.resetPassword({
                        ...this.passwordForm,
                        passWord: this.encryptor.encrypt(this.passwordForm.passWord) as string,
                        encryptPassword: this.encryptor.encrypt(this.passwordForm.encryptPassword) as string,
                        contract,
                        contractType: this.contractType,
                    })
                    this.step = step
                }
            })
        }
    }

    done () {
        location.assign('/supplier/login')
    }

    validateEmail (rule, value, callback) {
        if (value === '') {
            callback(new Error(this.$t('emailRequired') as string))
        }
        if (!regExpUtils.email.test(value)) {
            callback(new Error(this.$t('invalidFormat') as string))
        }
        callback()
    }

    validatePasswordConfirm (rule, value, callback) {
        if (!value) {
            callback(new Error(this.$t('passwordConfirmRequired') as string))
        }
        if (this.passwordForm.passWord !== this.passwordForm.encryptPassword) {
            callback(new Error(this.$t('passwordConfirmInvalid') as string))
        }
        callback()
    }

    validatePassword (rule, value, callback) {
        if (!value.length) {
            callback(new Error(this.$t('passwordRequired') as string))
        } else if (value.length < 8) {
            callback(new Error(this.$t('passwordValidate') as string))
        }
        const hasUpperCase = /[A-Z]/.test(value)
        const hasLowerCase = /[a-z]/.test(value)
        const hasNumber = /\d/.test(value)
        if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            callback(new Error(this.$t('passwordValidate') as string))
        }
        callback()
    }
}

