
import { Component, Vue } from 'vue-property-decorator'
import { setLocaleCookie, getLocaleFromCookie } from '@/locales'
import regExpUtils from '@/utils/regExp'
import { COMMON_COUNTRY, COMPANY_CATEGORY, INVITE_TYPE } from '@/enum'
import VuePhoneNumberInput from 'vue-phone-number-input'
import API, { IRegisterData } from '@/api/api'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import JSEncrypt from 'jsencrypt'
import TCaptcha007 from '@futuweb/business-act/es5/TCaptcha007'

interface IInfoForm {
    countryCode: string;
    name: string;
    userName: string;
    inviter: string;
    creditCode: string;
    creditCodeType: string;
}

interface IAccountForm {
    mobile: string;
    securityCode: string;
    email: string;
    passWord: string;
    loginName: string;
    countryCallingCode: string;
    passwordConfirm: string;
    mobileCountryCode: string;
}

@Component({
    name: 'Register',
    components: {
        VuePhoneNumberInput,
    },
})
export default class Register extends Vue {
    /* data */
    step = 1;

    companyCategoryEnum = COMPANY_CATEGORY

    commonCountryEnum = COMMON_COUNTRY

    invitedTypeEnum = INVITE_TYPE

    inviteType = this.invitedTypeEnum.MOBILE

    companyCategory = this.companyCategoryEnum.NORMAL_COMPANY

    isSending = false

    secondsRemaining = 60

    // 确认密码
    passwordConfirm = '';

    countryCallingCode = '';

    languageOpt = [
        {
            code: 'zh-CN',
            desc: '简体中文',
        },
        // {
        //     code: 'en-US',
        //     desc: 'English',
        // },
    ]

    usedLanguage = {
        code: 'zh-CN',
        desc: '简体中文',
    }

    registerForm: IInfoForm = {
        countryCode: '',
        name: '',
        userName: '',
        inviter: '',
        creditCode: '',
        creditCodeType: 'CREDIT_CODE',
    }

    personRegisterForm: IInfoForm = {
        countryCode: '',
        name: '',
        userName: '',
        inviter: '',
        creditCode: '',
        creditCodeType: 'PASSPORT',
    }

    mobileRegisterForm: IAccountForm = {
        mobile: '',
        securityCode: '',
        email: '',
        passWord: '',
        loginName: '',
        countryCallingCode: '',
        passwordConfirm: '',
        mobileCountryCode: '',
    }

    emailRegisterForm: IAccountForm = {
        mobile: '',
        securityCode: '',
        email: '',
        passWord: '',
        loginName: '',
        countryCallingCode: '',
        passwordConfirm: '',
        mobileCountryCode: '',
    }

    rules = {
        countryCode: [
            { required: true, message: this.$t('countryRequire'), trigger: ['blur'] },
        ],
        name: [
            { required: true, message: this.$t('companyRequire'), trigger: ['blur'] },
        ],
        creditCode: [
            { required: true, message: this.$t('creditCodeRequire'), trigger: ['blur'] },
            { trigger: ['blur'], validator: this.validateCreditCode },
        ],
        userName: [
            { required: true, message: this.$t('inviterRequire'), trigger: ['blur'] },
        ],
    }

    personRegisterRules = {
        countryCode: [
            { required: true, message: this.$t('countryRequire'), trigger: ['blur'] },
        ],
        name: [
            { required: true, message: this.$t('nameRequire'), trigger: ['blur'] },
        ],
        creditCode: [
            { required: true, message: this.$t('IDNumRequire'), trigger: ['blur'] },
            { required: true, trigger: 'blur', validator: this.validateIdNumber },
        ],
        userName: [
            { required: true, message: this.$t('inviterRequire'), trigger: ['blur'] },
        ],
    }

    mobileRegisterRules = {
        mobile: [
            { required: true, message: this.$t('mobileRequired'), trigger: ['blur'] },
        ],
        securityCode: [
            { required: true, message: this.$t('securityCodeRequired'), trigger: ['blur'] },
        ],
        email: [
            // { required: true, message: this.$t('emailRequired'), trigger: ['blur'] },
            { trigger: 'blur', validator: this.validateEmail },
        ],
        passWord: [
            { required: true, trigger: ['blur'], validator: this.validatePassword },
        ],
        passwordConfirm: [
            // { required: true, message: this.$t('passwordConfirmRequired'), trigger: ['blur'] },
            { required: true, trigger: 'blur', validator: this.validatePasswordConfirm },
        ],
        loginName: [
            { required: true, message: this.$t('loginNameRequied'), trigger: ['blur'] },
        ],
    }

    emailRegisterRules = {
        securityCode: [
            { required: true, message: this.$t('securityCodeRequired'), trigger: ['blur'] },
        ],
        email: [
            { required: true, message: this.$t('emailRequired'), trigger: ['blur'] },
            { required: true, trigger: 'blur', validator: this.validateEmail },
        ],
        passWord: [
            { required: true, trigger: ['blur'], validator: this.validatePassword },
        ],
        passwordConfirm: [
            // { required: true, message: this.$t('passwordConfirmRequired'), trigger: ['blur'] },
            { required: true, trigger: 'blur', validator: this.validatePasswordConfirm },
        ],
        loginName: [
            { required: true, message: this.$t('loginNameRequied'), trigger: ['blur'] },
        ],
    }

    countryList: any[] = []

    rsaKey = ''

    encryptor = new JSEncrypt()

    canRegister = false

    /* life cycle */
    async created () {
        document.title = '注册'
        await this.checkNonceValid()
        if (!this.$route.query.registerToken) {
            this.$message.error('邀请链接不合法')
            this.canRegister = false
            setTimeout(() => {
                location.assign('/supplier')
            }, 1000)
        }
        if (this.canRegister) {
            await Promise.all([
                this.syncLanguage(),
                this.getCountry(),
                this.getRsaKey(),
            ])
            this.registerForm.countryCode = COMMON_COUNTRY.MAINLAND
            this.personRegisterForm.countryCode = COMMON_COUNTRY.MAINLAND
        }
    }

    /* method */
    async checkNonceValid () {
        const { nonce } = this.$route.query
        try {
            const result = await API.checkNonceValid({
                nonce,
            })
            this.canRegister = true
        } catch (error: any) {
            setTimeout(() => {
                location.assign('/supplier')
            }, 1000)
        }
    }

    async getRsaKey () {
        const result = await API.getRsaKey()
        this.rsaKey = result.data
        this.encryptor.setPublicKey(this.rsaKey)
    }

    async getCountry () {
        const result = await API.getCountry()
        this.countryList = result.data
    }

    async changeLang (val: string) {
        if (this.usedLanguage.code === val) {
            return
        }
        setLocaleCookie(val)
        location.reload()
    }

    syncLanguage () {
        const lang = getLocaleFromCookie() || 'zh-CN'
        this.usedLanguage = this.languageOpt.filter((item) => {
            return item.code === lang
        })[0]
    }

    countryChange () {
        // 中国 156  香港 344 澳门 446 台湾158
        if (this.companyCategory === this.companyCategoryEnum.NORMAL_COMPANY) {
            // this.getConfig()
            if (this.registerForm.countryCode === this.commonCountryEnum.MAINLAND) {
                this.registerForm.creditCodeType = 'CREDIT_CODE'
            } else {
                this.registerForm.creditCodeType = 'REGISTER_CODE'
            }
        } else {
            if (this.personRegisterForm.countryCode === this.commonCountryEnum.MAINLAND) {
                this.personRegisterForm.creditCodeType = 'ID_CARD'
            } else if (this.personRegisterForm.countryCode === this.commonCountryEnum.HK) {
                this.personRegisterForm.creditCodeType = 'HK_ID_CARD'
            } else if (this.personRegisterForm.countryCode === this.commonCountryEnum.MACAU) {
                this.personRegisterForm.creditCodeType = 'MC_ID_CARD'
            } else if (this.personRegisterForm.countryCode === this.commonCountryEnum.TAIWAN) {
                this.personRegisterForm.creditCodeType = 'TW_ID_CARD'
            } else {
                this.personRegisterForm.creditCodeType = 'PASSPORT'
            }
            this.personRegisterForm.creditCode = ''
        }
    }

    next (step: number) {
        if (step === 1) {
            this.step = 1
        } else if (step === 2) {
            if (this.companyCategory === this.companyCategoryEnum.NORMAL_COMPANY) {
                (this.$refs.registerForm as any).validate((valid) => {
                    if (valid) {
                        this.step = step
                    }
                })
            } else {
                (this.$refs.personRegisterForm as any).validate((valid) => {
                    if (valid) {
                        this.step = step
                    }
                })
            }
        }
    }

    switchTab (tab: COMPANY_CATEGORY | INVITE_TYPE) {
        if (this.step === 1) {
            if (tab === COMPANY_CATEGORY.NORMAL_COMPANY) {
                (this.$refs.personRegisterForm as any).resetFields()
                this.registerForm.countryCode = COMMON_COUNTRY.MAINLAND
            } else {
                (this.$refs.registerForm as any).resetFields()
                this.personRegisterForm.countryCode = COMMON_COUNTRY.MAINLAND
            }
            this.companyCategory = tab as COMPANY_CATEGORY
        } else if (this.step === 2) {
            if (tab === INVITE_TYPE.MOBILE) {
                (this.$refs.emailRegisterForm as any).resetFields()
            } else {
                (this.$refs.mobileRegisterForm as any).resetFields()
            }
            this.inviteType = tab as INVITE_TYPE
        }
    }

    validateCreditCode (rule, value, callback) {
        if (value === '') {
            callback(new Error(this.$t('creditCodeRequire') as string))
        }
        if (this.registerForm.countryCode === COMMON_COUNTRY.MAINLAND) {
            if (!regExpUtils.creditCodeRegex.test(value)) {
                callback(new Error(this.$t('invalidFormat') as string))
            }
        }
        callback()
    }

    // 证件号码校验
    validateIdNumber (rule, value, callback) {
        if (value === '') {
            callback(new Error(this.$t('IDNumRequire') as string))
        }
        if (this.personRegisterForm.creditCodeType === 'ID_CARD' && !regExpUtils.mainlandIDCardRegex.test(value)) {
            callback(new Error(this.$t('invalidFormat') as string))
        }
        if (this.personRegisterForm.creditCodeType === 'PASSPORT' && !regExpUtils.passportRegex.test(value)) {
            callback(new Error(this.$t('invalidFormat') as string))
        }
        callback()
    }

    validateEmail (rule, value, callback) {
        if (value === '') {
            callback()
        }
        if (!regExpUtils.email.test(value)) {
            callback(new Error(this.$t('invalidFormat') as string))
        }
        callback()
    }

    validatePasswordConfirm (rule, value, callback) {
        if (!value) {
            callback(new Error(this.$t('passwordConfirmRequired') as string))
        }
        if (this.inviteType === INVITE_TYPE.MOBILE) {
            if (this.mobileRegisterForm.passWord !== this.mobileRegisterForm.passwordConfirm) {
                callback(new Error(this.$t('passwordConfirmInvalid') as string))
            }
        } else {
            if (this.emailRegisterForm.passWord !== this.emailRegisterForm.passwordConfirm) {
                callback(new Error(this.$t('passwordConfirmInvalid') as string))
            }
        }
        callback()
    }

    validatePassword (rule, value, callback) {
        if (!value.length) {
            callback(new Error(this.$t('passwordRequired') as string))
        } else if (value.length < 8) {
            callback(new Error(this.$t('passwordValidate') as string))
        }
        const hasUpperCase = /[A-Z]/.test(value)
        const hasLowerCase = /[a-z]/.test(value)
        const hasNumber = /\d/.test(value)
        if (!hasUpperCase || !hasLowerCase || !hasNumber) {
            callback(new Error(this.$t('passwordValidate') as string))
        }
        callback()
    }

    changePhone (val) {
        if (this.inviteType === INVITE_TYPE.MOBILE) {
            this.mobileRegisterForm.countryCallingCode = val.countryCallingCode
            this.mobileRegisterForm.mobileCountryCode = val.countryCode
        } else {
            this.emailRegisterForm.countryCallingCode = val.countryCallingCode
            this.emailRegisterForm.mobileCountryCode = val.countryCode
        }
    }

    onMobileBlur () {
        if (!this.mobileRegisterForm.mobile) {

        }
    }

    done () {
        if (this.inviteType === INVITE_TYPE.MOBILE) {
            (this.$refs as any).mobileRegisterForm.validate((valid) => {
                if (valid) {
                    this.register()
                }
            })
        } else {
            (this.$refs as any).emailRegisterForm.validate((valid) => {
                if (valid) {
                    this.register()
                }
            })
        }
    }

    async register () {
        let infoForm: IInfoForm
        if (this.companyCategory === COMPANY_CATEGORY.NORMAL_COMPANY) {
            infoForm = {
                ...this.registerForm,
            }
        } else {
            infoForm = {
                ...this.personRegisterForm,
            }
        }
        let accountForm: IAccountForm
        if (this.inviteType === INVITE_TYPE.MOBILE) {
            accountForm = {
                ...this.mobileRegisterForm,
            }
        } else {
            accountForm = {
                ...this.emailRegisterForm,
            }
        }
        const registerData: IRegisterData = {
            countryCode: infoForm.countryCode,
            name: infoForm.name,
            userName: infoForm.userName,
            creditCode: infoForm.creditCode,
            creditCodeType: infoForm.creditCodeType,
            mobile: accountForm.mobile.replaceAll(' ', ''),
            securityCode: accountForm.securityCode,
            email: accountForm.email,
            passWord: this.encryptor.encrypt(accountForm.passWord) as string,
            encryptPassword: this.encryptor.encrypt(accountForm.passwordConfirm) as string,
            loginName: accountForm.loginName,
            countryCallingCode: accountForm.countryCallingCode,
            mobileCountryCode: accountForm.mobileCountryCode,
            invitedType: this.inviteType,
            companyCategory: this.companyCategory,
        }
        const { nonce, registerToken } = this.$route.query
        console.log('registerToken', encodeURI(this.$route.query.registerToken as string))
        const result = await API.register(registerData, nonce as string, encodeURI(this.$route.query.registerToken as string))
        // 重定向登录页面
        location.assign('/supplier/login')
    }

    getCaptcha () {
        if (this.inviteType === this.invitedTypeEnum.MOBILE) {
            if (this.mobileRegisterForm.mobile) {
                this.showCaptcha()
            } else {
                this.$message.warning(this.$t('mobileRequired') as string)
            }
        } else {
            if (this.emailRegisterForm.email) {
                this.showCaptcha()
            } else {
                this.$message.warning(this.$t('emailRequired') as string)
            }
        }
    }

    showCaptcha () {
        const captcha = new TCaptcha007(
            {
                // appid
                appid: 2094350478,
                option: {
                    userLanguage: 'zh-cn',
                    bizState: null,
                },
                // 是否使用国际站防水墙
                useForeignCaptcha: false,
                success: (res) => {
                    // res.ticket 验证票据
                    // res.appid appid
                    // res.randstr 随机字符串（用于传回给后端）
                    // 调用验票接口
                    this.verifyTicket(res)
                },
                fail: (err) => {
                    // errCode 错误码，如1006
                    // errorMessage 错误信息，如 "get_captcha_config_request_error"
                    if (err) {}
                },
                close: (res) => {
                    // console.log('res', res)
                },
            },
        )
        captcha.show()
    }

    async getAuthCode (ticket) {
        await API.getAuthCode(
            {
                contract: (
                    this.inviteType === INVITE_TYPE.MOBILE
                        ? this.mobileRegisterForm.mobile.replaceAll(' ', '')
                        : this.emailRegisterForm.email
                ),
                contractType: this.inviteType === INVITE_TYPE.MOBILE ? 'MOBILE' : 'EMAIL',
                source: 'register',
                numCode: (
                    this.companyCategory === COMPANY_CATEGORY.NORMAL_COMPANY ? this.registerForm.countryCode : this.personRegisterForm.countryCode
                ),
                ticket,
            },
        )
        this.isSending = true
        this.countdown()
    }

    async verifyTicket (data) {
        // const result = await API.verifyTicket(data)
        await this.getAuthCode(data)
    }

    countdown () {
        // Decrement the seconds remaining and update the button text
        this.secondsRemaining--
        if (this.secondsRemaining > 0) {
            setTimeout(() => {
                this.countdown()
            }, 1000)
        } else {
        // Reset the button text and seconds remaining
            this.isSending = false
            this.secondsRemaining = 60
        }
    }
}

