import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import Vue from 'vue'

// 创建 axios 实例
const http = axios.create({
    // 配置项
    timeout: 10000, // 10s超时
    // headers: {
    //     'X-Requested-With': 'XMLHttpRequest',
    // },
    withCredentials: true,
})

// 请求拦截
http.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        // 错误抛出
        error.data = {}
        error.data.msg = '请求异常，请联系管理员！'
        return Promise.resolve(error)
    },
)

// 响应拦截
http.interceptors.response.use(
    (response) => {
        if (response.data instanceof Blob || response.data instanceof ArrayBuffer) {
            return response.data
        }
        const res = response.data
        // 登录态失效
        if (res.code === 140001000) {
            location.reload()
        }
        if (res.code !== 0) {
            Vue.prototype.$message.error(res.message)
            return Promise.reject(new Error(res.message || 'Error'))
        }
        return res
    },
    (error) => {
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            Vue.prototype.$message.error('请求超时，请稍后重试')
        } else if (error?.response?.data.message) {
            Vue.prototype.$message.error(error?.response?.data.message)
        } else {
            Vue.prototype.$message.error(error.message)
        }
        return Promise.reject(error)
    },
)

export default http
