import http from './request'
import { AxiosPromise, AxiosResponse } from 'axios'
import { COMPANY_CATEGORY, INVITE_TYPE } from '@/enum'

export interface IGetAuthCodeData {
    contract: string;
    source: string;
    numCode: string;
    contractType: string;
    ticket?: any;
}

export interface IRegisterData {
    mobile: string;
    countryCallingCode: string;
    invitedType: INVITE_TYPE;
    securityCode: string;
    email: string;
    passWord: string;
    encryptPassword: string;
    loginName: string;
    countryCode: string;
    companyCategory: COMPANY_CATEGORY;
    name: string;
    creditCode: string;
    creditCodeType: string;
    mobileCountryCode: string;
    userName: string;
}

export interface IResetCheckData {
    contract: string;
    securityCode: string;
    contractType: string;
}

export interface IResetData {
    passWord: string;
    encryptPassword: string;
    contract: string;
    contractType: string;
}

export interface ILoginData {
    username: string;
    password: string;
    securityCode: string;
}

export interface IRequest {
    getCountry(): AxiosPromise;
    getRsaKey(): AxiosPromise;
    register(data: IRegisterData, nonce: string, registerToken: string): AxiosPromise;
    getAuthCode(data: IGetAuthCodeData): AxiosPromise;
    checkReset(data: IResetCheckData): AxiosPromise;
    resetPassword(data: IResetData): AxiosPromise;
    login(data: ILoginData): Promise<{code: number; message: string}>;
    verifyTicket(data: any): AxiosPromise<{code: number; message: string}>;
    checkNonceValid(data: any): AxiosPromise;
}

const api: IRequest = {
    getCountry: async function () {
        return await http.post('/api/getCountry')
    },
    getRsaKey: async function () {
        return await http.post('/api/getRsaPublicKey')
    },
    register: async function (data, nonce, registerToken) {
        return await http.post(`/api/register?nonce=${nonce}&registerToken=${registerToken}`, data)
    },
    getAuthCode: async function (data) {
        return await http.post('/api/getAuthCode', data)
    },
    checkReset: async function (data) {
        return await http.post('/api/resetCheck', data)
    },
    resetPassword: async function (data) {
        return await http.post('/api/resetPassword', data)
    },
    login: async function (data) {
        return await http.post('/api/login', data)
    },
    verifyTicket: async function (data) {
        return await http.post('/api/verifyTicket', data)
    },
    checkNonceValid: async function (data) {
        return await http.post('/api/checkNonceValid', data)
    },

}

export default api
